<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <!-- <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center bg_color--1"
            data-black-overlay="1" style="height:30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-h2 text-left font-weight-bold"
                            style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                            Together, we grow and create impact
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light"
                            style="line-height: 40px !important; font-size: 24px; color: #717173 !important;">
                            our door never closes for talented people who are ready to grow and solve challenging problems. Make Your Move!.
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <div class="photo-background">
                <span>
                    <img src="../assets/images/bg/colleagues-giving-fist-bump.jpg" alt="" style="background-repeat: no-repeat; background-size: cover; background-position: center center;"/>
                </span>
            </div>
        </div> -->
        <!-- End of Slider -->

        <!-- <div class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix" style="position: relative; height: 100%; margin:0;"
        data-black-overlay="1">

            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-h2 text-left font-weight-bold"
                            style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                            Together, we grow and create impact
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light"
                            style="line-height: 40px !important; font-size: 24px; color: #fffff !important;">
                            our door never closes for talented people who are ready to grow and solve challenging problems. Make Your Move!.
                        </h5>
                        <div class="slide-btn">
                            <router-link
                            class="rn-button-style--2siber btn_solid"
                            to="#siber-career"
                            >Check it out
                            </router-link>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <div class="photo-background" style="object-fit: cover;">
                <img src="../assets/images/bg/confident-business-people-standing-together.jpg" style="background-repeat: no-repeat; background-attachment: fixed; background-position: center !important; " class="foto-homepage" alt=""/>
            </div>
        </div> -->

        <!-- Start Content -->
        <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px" id="siber-career">
            <div class="contact-form--1">
                <v-container class="pt-5 pb-16">
                    <!-- <div class="section rn-blog-area rn-section-gap" id="blog"> -->
                        <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25386364' frameborder='0' width='100%' height='1000'></iframe>
                    <!-- </div> -->
                </v-container>
            </div>
        </div>  
        <!-- End Content -->

        <!-- Start Content -->
        <!-- <div class="rn-contact-area rn-section-gap bg-career2" style="padding-top:0px; padding-bottom:0px " id="siber-career">
            <div class="contact-form--1">
                <v-container class="py-16">
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <span style="color: #e8ebf0">READY TO DO THIS</span>
                        <h2 style="color: #e8ebf0; font-size: 81px; font-weight: 900; line-height: 90px;">Let's get to work</h2>
                    </div>
                </v-container>
            </div>
        </div>   -->
        <!-- End Content -->

        <!-- Start Content -->
        <!-- <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px" id="siber-career">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-center mb--20">
                            <h4 class="heading-title">We're Open</h4>
                            <h3 class="heading-title mb-5">
                                Available Positions
                            </h3>
                            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                        </div>
                        <ServiceCareer/>
                    </div>
                </v-container>
            </div>
        </div>   -->
        <!-- End Content -->

        
        
        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
// import ServiceCareer from "../components/service/ServiceCareer.vue";
import Footer from "../components/footer/Footer";

export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        // ServiceCareer,
        Footer
    },
    methods: {
    },
    watch: {
       
    }
};
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.bg-career2 {
    background-image: url(../assets/images/bg/colleagues-giving-fist-bump.jpg);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
  